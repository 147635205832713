import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export function LoggedInGuard(): boolean {
  const router = inject(Router);
  const authService = inject(AuthenticationService);

  if (authService.isLoggedIn && !authService.isLogoutStarted) {
    router.navigate(['/dashboard']);
    return false;
  }

  return true;

}
