<app-header></app-header>
<div class="main-content-wrapper">
  <aside [ngClass]="{ 'sidebar-open': sidebarStatus() }">
    <div class="scroller-wrapper">
      <app-sidebar></app-sidebar>
    </div>
  </aside>
  <main>
    <router-outlet></router-outlet>
    <app-footer *ngIf="showFooter"></app-footer>
  </main>
</div>

