import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SetupService } from '../services/setup.service';

@Injectable({ providedIn: 'root' })
export class EnabledAgreementsGuard implements CanActivate {

  constructor(
    private router: Router,
    private setupService: SetupService
  ) { }

  canActivate(): boolean {
    if (!this.setupService.areAgreementsEnabled) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    return true;
  }
}
