import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {
  TIME_LIMIT = 120;
  FULL_DASH_ARRAY = 283;
  timeLeft: number;
  circleDasharray = '283';
  counterVisible$: Observable<number>;
  timerInterval = null;

  constructor(
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.counterVisible$ = this.loaderService.counter.pipe(
      tap((value) => {
        if (value) {
          this.TIME_LIMIT = value;
          this.startTimer();
        } else {
          clearInterval(this.timerInterval);
        }
      })
    );
  }

  startTimer(): void {
    this.timerInterval = null;
    this.timeLeft = this.TIME_LIMIT;
    let timePassed = 0;
    this.setCircleDasharray();
    this.timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        timePassed = timePassed += 1;
        this.timeLeft = this.TIME_LIMIT - timePassed;
        this.setCircleDasharray();
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  calculateTimeFraction(): number {
    const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
    return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
  }

  setCircleDasharray(): void {
    this.circleDasharray = `${(
      this.calculateTimeFraction() * this.FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
  }
}
