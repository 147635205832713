<div class="aside-content-wrapper" #asideContentWrapper>
  <div class="aside-content"
       *ngIf="isSidebarOpened()"
       @fadeAnimation
       appOutsideClick
       (outsideClick)="closeSidebar()">
    <div class="aside-container">
      <div *ngIf="user"
           class="user-info">
        <div class="user-image-container">
          <i *ngIf="!user.picture"
             class="icon-avatar"></i>
          <img class="user-image"
               *ngIf="user.picture"
               [src]="user.picture" />
        </div>
        <span class="user-name">{{ user.name }}</span>
      </div>
      <nav class="nav-container">
        <div class="nav-section" [ngClass]="{ hidden: category.hide }" *ngFor="let category of sideMenuCategories">
          <header class="section-header" *ngIf="!category.hide"
                  (click)="expandCategory(category)">
            <h3 class="section-title">{{ category.title | translate }}</h3>
            <div class="section-icon">
              <i class="icon-chevron-right"
                 [ngClass]="{ open: category.expand }"></i>
            </div>
          </header>
          <div @expandAnimation1 (@expandAnimation1.done)="animationDone()" *ngIf="category.expand"  class="expand-wrapper">
            <ul class="nav-links-list">
              <ng-container *ngFor="let navLink of category.children">
                <ng-container *ngIf="!navLink.hide">
                  <li class="list-item"
                      *allowForRoles="navLink.allowForRoles || []"
                      routerLinkActive="active"
                      [class]="navLink.class ? navLink.class : ''">
                    <a class="navigation-link"
                        [routerLink]="[navLink.link]"
                        [queryParams]="(isEnterpriseUser && navLink.queryParams) ? {companyId: companyId} : null"
                        routerLinkActive="active">{{ navLink.title | translate }}</a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="nav-section" *ngIf="host.includes('localhost')">
          <h3 class="section-title">
            <a routerLink="translation">
              <span>Translation</span>
            </a>
          </h3>
        </div>
        <div class="nav-section" *ngIf="!isEnterprise && isEnterpriseUser">
          <h3 class="section-title">
            <a (click)="backToEnterprise()">
              <span>{{ 'SHARED.BACK_TO_ENTERPRISE' | translate }}</span>
              <i class="icon-arrow-left-in-circle"></i>
            </a>
          </h3>
        </div>
        <div class="nav-section">
          <h3 class="section-title">
            <a (click)="onLogout()">
              <span>{{ 'SIDEBAR.LOGOUT_LINK' | translate }}</span>
              <i class="icon-arrow-left-in-circle"></i>
            </a>
          </h3>
        </div>
      </nav>
    </div>
    <div class="application-version">{{ applicationVersion }}</div>
  </div>
  <button class="toggle-button"
          [ngStyle]="{
            right: isSidebarOpened() ? scrollBarWidth + 'px' : 0,
            width: isSidebarOpened() ? 'calc(' + toggleButtonWidth + ' - ' + scrollBarWidth + 'px)' : toggleButtonWidth
          }"
          (click)="toggleSidebar()">
    <span class="toggle-button-text"
          [ngStyle]="{
            transform: isSidebarOpened() ? 'translateX(' + scrollBarWidth / 2 + 'px) rotate(-90deg)' : 'rotate(-90deg)'
          }">
      {{ (isSidebarOpened() ? 'SIDEBAR.CLOSE_BUTTON' : 'SIDEBAR.OVERVIEW_BUTTON') | translate }}
    </span>
  </button>
</div>
