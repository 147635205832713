import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class EnabledAgreementFormsGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(): boolean {
    if (this.userService.user.allowedAgreementForms) {
      return true;
    }

    this.router.navigate(['dashboard'], { queryParamsHandling: 'merge' });
    return false;
  }
}
