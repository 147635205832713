import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { SetupService } from '../services/setup.service';

export function EnabledRequisitionsGuard(): boolean {
  const router = inject(Router);
  const setupService = inject(SetupService);

  if (!setupService.isRequisitionFormFeatureAllowed) {
    router.navigate(['/dashboard']);
    return false;
  }

  return true;

}
