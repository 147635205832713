import { Component } from '@angular/core';
import { UpToDateBuildService } from './services/up-to-date.service';
import { ConfirmationModalData } from './model/modal.interface';
import { ModalService } from './services/modal.service';
import { ConfirmationModal } from './classes/modal.class';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-up-to-date',
  template: ''
})
export class UpToDateComponent {

  public buildIsUpToDate = true;
  updateModalOpened: boolean;

  constructor(
    private upToDateService: UpToDateBuildService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    ) {
      this.upToDateService.buildIsUpToDate.subscribe(buildIsUpToDate => {
      this.buildIsUpToDate = buildIsUpToDate;
      if (!buildIsUpToDate && !this.updateModalOpened && !this.checkIfIsCandidate()) {
        const data: ConfirmationModalData = {
          title: 'UPDATE_MODAL.UPDATE_MODAL_TITLE',
          question: 'UPDATE_MODAL.UPDATE_MODAL_QUESTION',
          confirmBtnTitle: 'UPDATE_MODAL.UPDATE_MODAL_BUTTON',
          noCancelBtn: true,
          doNotCloseOnBackdrop: true,
          confirm: () => this.onSubmit()
        };

        this.modalService.addModal(new ConfirmationModal(data));
        this.updateModalOpened = true;
      }
    });
  }

  checkIfIsCandidate(): boolean {
    let isCandidate = false;
    const candidateRoutes = ['resume', 'email-reply', 'add_info', 'candidate-video-interview', 'candidate-group-video-interview'];
    candidateRoutes.forEach(route => {
      if (this.router.url.includes(route)) {
        isCandidate = true;
      }
    });
    const otherCandidateRoutes = ['decline-interview', 'accept-interview'];
    const { enterpriseManager, customer, thirdParty } = this.route.snapshot.queryParams;
    otherCandidateRoutes.forEach(route => {
      if (this.router.url.includes(route) && !enterpriseManager && !customer && !thirdParty) {
        isCandidate = true;
      }
    });
    return isCandidate;
  }

  onSubmit(): void {
    document.location.reload();
  }
}
