import { Component, OnInit, Signal, ViewEncapsulation } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-basic-layout',
  templateUrl: 'basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicLayoutComponent implements OnInit {

  blacklistedRoutes: string[] = ['hr', 'recruitment?', 'templates', 'video-interview'];
  sidebarStatus: Signal<boolean>;
  showFooter = true;

  constructor(
    private sidebarService: SidebarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd)
      )
      .subscribe(({url}: NavigationEnd) => {
        this.showFooter = this.setFooterVisibilityFlag(url);
      });

    this.showFooter = this.setFooterVisibilityFlag(this.router.url);

    this.sidebarStatus = this.sidebarService.sidebarStatus;
  }

  setFooterVisibilityFlag(url: string): boolean {
    let footerVisible = true;

    this.blacklistedRoutes.forEach((route: string) => {
      if (url.includes(route)) {
        footerVisible = false;
      }
    });

    return footerVisible;
  }
}
