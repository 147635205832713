import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { DynamicModalComponent } from './dynamic-modal/dynamic-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { SharedModule } from '../shared/shared.module';
import { DeleteUserModalComponent } from './delete-user-modal/delete-user-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlatformOperationModalComponent } from './platform-operation-modal/platform-operation-modal.component';
import { PermissionsModalComponent } from './permissions-modal/permissions-modal.component';
import { UpdatePhoneModalComponent } from './update-phone-modal/update-phone-modal.component';
import { UniversalModalComponent } from './universal-modal/universal-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchiveJobModalComponent } from './archive-job-modal/archive-job-modal.component';

@NgModule({
  declarations: [
    ModalContainerComponent,
    DynamicModalComponent,
    ConfirmationModalComponent,
    DeleteUserModalComponent,
    PlatformOperationModalComponent,
    PermissionsModalComponent,
    UpdatePhoneModalComponent,
    UniversalModalComponent,
    ArchiveJobModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule
  ],
  exports: [
    ModalContainerComponent,
    DynamicModalComponent,
    ConfirmationModalComponent,
    DeleteUserModalComponent,
    PlatformOperationModalComponent,
    UpdatePhoneModalComponent,
  ]
})
export class ModalModule { }

