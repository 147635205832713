import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { BuildDetails } from '../classes/build-details.class';

@Injectable({ providedIn: 'root' })
export class BuildDetailsService {
  private _buildDetails: string;

  get buildDetails(): string {
    return this._buildDetails;
  }

  constructor(private http: HttpClient) { }

  fetchBuildDetails(): Promise<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache'
      })
    };

    return new Promise<void>(
      (resolve) => {
        lastValueFrom(this.http.get<BuildDetails>('assets/build-details.json', httpOptions))
          .then(response => {
            this._buildDetails = response.buildNumber;
            resolve();
          });

      }
    );
  }
}
