import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { BuildDetailsService } from './build-details.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BuildDetails } from '../classes/build-details.class';

@Injectable({ providedIn: 'root' })
export class UpToDateBuildService {

  private buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);
  private buildNumberAtStartup: string;

  constructor(
    private buildDetailsService: BuildDetailsService,
    private httpClient: HttpClient
    ) {
    this.buildNumberAtStartup = this.buildDetailsService.buildDetails;
    this.pollForBuildNumber();
  }

  public get buildIsUpToDate(): Observable<boolean> {
    return this.buildIsUpToDateSubject;
  }

  private pollForBuildNumber(): void {

    const pollInterval = 5 * 60 * 1000; // 5 minutes

    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache'
      })
    };

    timer(pollInterval, pollInterval).subscribe(() => {
      this.httpClient.get<BuildDetails>('assets/build-details.json', httpOptions).subscribe(response => {
        const newBuildNumber = response.buildNumber;
        if (this.buildNumberAtStartup !== newBuildNumber) {
          this.buildNumberAtStartup = newBuildNumber;
          this.buildIsUpToDateSubject.next(false);
        }
      });
    });
  }
}
