import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BasicLayoutComponent } from './basic-layout/basic-layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import { LoaderComponent } from './loader/loader.component';
import { CountdownComponent } from './countdown/countdown.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    LoaderComponent,
    CountdownComponent
  ],
  declarations: [
    BasicLayoutComponent,
    SidebarComponent,
    LoaderComponent,
    CountdownComponent
  ],
  providers: [],
})
export class LayoutsModule { }
