import { Roles } from '../model/role.interface';
import { SidemenuCategory } from '../model/sidemenu-categories.interface';

export const ENTERPRISE_SIDE_BAR: SidemenuCategory[] = [
  {
    expand: false,
    links: ['/dashboard'],
    title: 'SIDEBAR.DASHBOARD_TITLE',
    children: [
      {
        title: 'SIDEBAR.VISIT_DASHBOARD_LINK',
        link: '/dashboard/enterprise'
      }
    ]
  },
  {
    expand: false,
    links: ['/support-and-feedback', '/users', '/hr'],
    title: 'SIDEBAR.SETTINGS_TITLE',
    children: [
      {
        title: 'SIDEBAR.CREATE_NEW_USER_LINK',
        link: '/users/enterprise-user-management',
        allowForRoles: [Roles.enterpriseHR]
      },
      {
        title: 'SHARED.USER_PROFILE',
        link: '/users/enterprise/user-profile'
      },
      {
        title: 'SIDEBAR.HR',
        link: '/hr/enterprise/dashboard',
        class: 'd-none d-xl-block',
        allowForRoles: [Roles.enterpriseHR]

      }
    ]
  }
];
