import { ChangeDetectionStrategy, Component, OnInit, Renderer2 } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { Modal } from '../../../classes/modal.class';
import { Observable } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('modalAnimation', [
      transition(':leave', [
        style({opacity: 1, transform: 'translateY(0)'}),
        animate('0.3s', style({opacity: 0, transform: 'translateY(-20%)'}))
      ]),
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(-20%)'}),
        animate('0.3s', style({opacity: 1, transform: 'translateY(0)'}))
      ]),
    ]),
  ],
})
export class ModalContainerComponent implements OnInit {

  modals$: Observable<Modal[]>;

  constructor(
    private modalService: ModalService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.modalService.renderer = this.renderer;
    this.modals$ = this.modalService.modals$;
  }
}
