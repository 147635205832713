import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { Token } from '../model/token.interface';


export const blacklist = [
  environment.auth,
  environment.refresh,
  environment.forgottenPassword,
  environment.resetPassword,
  environment.integration
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  refreshing = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.setLanguageHeaders(request);

    const reqUrl = request.url;
    const isblacklistedURL = !!blacklist.find(blUrl => reqUrl.includes(blUrl) && !reqUrl.includes(environment.logout));

    if (isblacklistedURL) {
      return next.handle(request);
    }

    const token = localStorage.getItem('token');

    if (token) {
      const userService = this.injector.get<UserService>(UserService);
      const authService = this.injector.get<AuthenticationService>(AuthenticationService);
      const userFromToken = this.getUserFromToken(token);
      const userFromService = userService.user?.email;

      if (userFromService && userFromToken && userFromToken !== userFromService && !reqUrl.includes(environment.self)) {
        authService.removeTokens();
      } else {
        request = this.addToken(request, token);
      }
    }

    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handleAuthError(request, next);
          } else {
            return throwError(() => error);
          }
        })
      );
  }

  addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        SpxAuth: `Bearer ${token}`,
      }
    });
  }

  setLanguageHeaders(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Accept-Language': this.translateService.currentLang || this.translateService.defaultLang || 'en',
      }
    });
  }

  handleAuthError(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const authService = this.injector.get<AuthenticationService>(AuthenticationService);

    if (this.refreshing) {
      return this.refreshTokenSubject
        .pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(this.addToken(request, token));
          })
        );
    }

    this.refreshing = true;
    this.refreshTokenSubject.next(null);


    return authService.refreshTokens()
      .pipe(
        switchMap(({token}: Token) => {
          this.refreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(request, token));
        }),
        catchError((error) => {
          this.refreshing = false;
          authService.onRefreshTokenExpired();
          return throwError(() => error);
        })
      );
  }

  getUserFromToken(token: string): string {
    const convertedToken = window.atob(token.split('.')[1]);

    return JSON.parse(convertedToken)?.username;
  }
}
