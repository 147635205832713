import { animate, style, transition, trigger } from '@angular/animations';

const DEFAULT_ZERO_STYLES = {
  opacity: 0,
  width: 0,
  'margin-left': 0,
  'margin-right': 0,
  'padding-left': 0,
  'padding-right': 0,
  'white-space': 'nowrap'
};

const DEFAULT_MAX_STYLES = {
  opacity: 1,
  width: '*',
  'margin-left': '*',
  'margin-right': '*',
  'padding-left': '*',
  'padding-right': '*'
};

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style(DEFAULT_ZERO_STYLES),
    animate('0.3s ease-in', style(DEFAULT_MAX_STYLES))
  ]),
  transition(':leave', [
    style({...DEFAULT_MAX_STYLES, 'white-space': 'nowrap' }),
    animate('0.3s ease-out', style(DEFAULT_ZERO_STYLES))
  ])
]);
