import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicLayoutComponent } from './modules/layout/basic-layout/basic-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { LoggedInGuard } from './guards/loggedin.guard';
import { HRSettingsGuard } from './guards/hr-settings.guard';
import { environment } from 'src/environments/environment';
import { EnabledInterviewGuard } from './guards/enabled-interview.guard';
import { EnabledRequisitionsGuard } from './guards/enabled-requisitions.guard';
import { EnabledAgreementsGuard } from './guards/enabled-agreements.guard';
import { EnabledAgreementFormsGuard } from './guards/agreement.guard';

const routes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'jobs',
        loadChildren: () => import('./modules/job/job.module').then(m => m.JobModule)
      },
      {
        path: 'recruitment',
        loadChildren: () => import('./modules/recruitment/recruitment.module').then(m => m.RecruitmentModule)
      },
      {
        path: 'interview',
        loadChildren: () => import('./modules/interview/interview.module').then(m => m.InterviewModule),
        canActivate: [EnabledInterviewGuard]
      },
      {
        path: 'support-and-feedback',
        loadChildren: () => import('./modules/support-and-feedback/support-and-feedback.module').then(m => m.SupportAndFeedbackModule)
      },
      {
        path: 'kpi-statistics',
        loadChildren: () => import('./modules/kpi-statistics/kpi-statistic.module').then(m => m.KpiStatisticsModule)
      },
      {
        path: 'hr',
        loadChildren: () => import('./modules/hr/hr.module').then(m => m.HrModule),
        canActivate: [HRSettingsGuard]
      },
      {
        path: 'hr/enterprise',
        loadChildren: () => import('./modules/hr/hr.module').then(m => m.HrModule),
        canActivate: [HRSettingsGuard]
      },
      {
        path: 'templates',
        loadChildren: () => import('./modules/templates/templates.module').then(m => m.TemplatesModule)
      },
      {
        path: 'marketplace',
        loadChildren: () => import('./modules/marketplace/marketplace.module').then(m => m.MarketplaceModule)
      },
      {
        path: 'customer-video-interview',
        loadChildren: () => import('./modules/customer-video-interview/customer-video-interview.module')
          .then(m => m.CustomerVideoInterviewModule),
      },
      {
        path: 'customer-group-video-interview',
        loadChildren: () => import('./modules/customer-video-interview/customer-video-interview.module')
          .then(m => m.CustomerVideoInterviewModule),
      },
      {
        path: 'requisitions',
        loadChildren: () => import('./modules/requisition/requisition.module').then(m => m.RequisitionModule),
        canActivate: [EnabledRequisitionsGuard]
      },
      {
        path: 'agreements',
        loadChildren: () => import('./modules/agreement/agreement.module').then(m => m.AgreementModule),
        canActivate: [EnabledAgreementsGuard, EnabledAgreementFormsGuard]
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'platform-integration',
    loadChildren: () => import('./modules/integration/integration.module').then(m => m.IntegrationModule)
  },
  {
    path: 'auth',
    loadComponent: () => import('./modules/two-factor-auth/auth.component').then(m => m.AuthComponent),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('./modules/login/login.component').then(m => m.LoginComponent),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./modules/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./modules/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'password-notify',
    loadComponent: () => import('./modules/password-notify/password-notify.component').then(m => m.PasswordNotifyComponent),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'page-not-found',
    loadComponent: () => import('./modules/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'resume',
    loadChildren: () => import('./modules/upload-resume/upload-resume.module').then(m => m.UploadResumeModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'email-reply',
    loadChildren: () => import('./modules/email-reply/email-reply.module').then(m => m.EmailReplyModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'add_info',
    loadChildren: () => import('./modules/add-candidate-info/add-candidate-info.module').then(m => m.AddCandidateInfoModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'decline-interview',
    loadChildren: () => import('./modules/decline-interview/decline-interview.module').then(m => m.DeclineInterviewModule),
  },
  {
    path: 'accept-interview',
    loadChildren: () => import('./modules/accept-interview/accept-interview.module').then(m => m.AcceptInterviewModule),
  },
  {
    path: 'candidate-video-interview',
    loadChildren: () => import('./modules/candidate-video-interview/candidate-video-interview.module')
      .then(m => m.CandidateVideoInterviewModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'candidate-group-video-interview',
    loadChildren: () => import('./modules/candidate-video-interview/candidate-video-interview.module')
      .then(m => m.CandidateVideoInterviewModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'external-video-interview',
    loadChildren: () => import('./modules/candidate-video-interview/candidate-video-interview.module')
      .then(m => m.CandidateVideoInterviewModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'external-group-video-interview',
    loadChildren: () => import('./modules/candidate-video-interview/candidate-video-interview.module')
      .then(m => m.CandidateVideoInterviewModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: '**',
    loadComponent: () => import('./modules/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    canActivate: [AuthGuard]
  },
];

if (!environment.production) {
  const translationRoute = {
    path: 'translation',
    loadChildren: () => import('./modules/translation/translation.module').then(m => m.TranslationModule)
  };

  routes.splice(routes.length - 1, 0, translationRoute);
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
