import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DragulaModule } from 'ng2-dragula';

import { LayoutsModule } from './modules/layout/layouts.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from './resources/http-loader-factory';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InitializeService } from './services/initialize.service';
import { loadConfiguration } from './resources/load-configuration';
import { ModalModule } from './modules/modal/modal.module';
import { BuildDetailsService } from './services/build-details.service';
import { UpToDateComponent } from './up-to-date.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    AppComponent,
    UpToDateComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
      easeTime: 1000,
      progressBar: true,
      extendedTimeOut: 3000
    }),
    QuillModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    LayoutsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DragulaModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfiguration,
      deps: [InitializeService,
        BuildDetailsService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
